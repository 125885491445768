import { useCallback, useContext, useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import axios from "axios"
import { Button, Col, message, Modal, Row, Spin } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import "../assets/styles/AnalizarPaciente.css"
import { DeleteOutlined } from "@ant-design/icons"
import imageCompression from "browser-image-compression"
import moment from "moment"
import { setTokenToSessionStorage } from "../services/SessionStorageServices"
import { useDropzone } from "react-dropzone"
import FloatingInput from "../components/FloatingInput"
import FloatingSelect from "../components/FloatingSelect"
import FloatingDatePicker from "../components/FloatingDatePicker"
import { ArrowRight, Trash2, UploadCloud } from "lucide-react"
import { createReport } from "../services/ReportService"
import { useAuth } from "../contexts/authContext"
import { getRequestingProfessionals, deleteRequestingProfessional } from "../services/RequestingProfessionalServices"
import { ImageContext } from "../contexts/imageContext/imageContext"

const AnalizarPaciente = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { images: imagenes, addImages, removeImage, clearImages, reorderImages } = useContext(ImageContext)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [breedSuggestions, setBreedSuggestions] = useState([])
  const [loadingBreeds, setLoadingBreeds] = useState(false)
  const [showBreedSuggestions, setShowBreedSuggestions] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [touchedFields, setTouchedFields] = useState({})
  const [loadingProfessionals, setLoadingProfessionals] = useState(true)
  const [emailDisabled, setEmailDisabled] = useState(false)
  const [professionals, setProfessionals] = useState([])
  const [loadingImages, setLoadingImages] = useState(false)

  const [formValues, setFormValues] = useState(() => {
    const savedFormValues = JSON.parse(sessionStorage.getItem("formValues") || "{}")
    return {
      tutor: savedFormValues.tutor || "",
      tutor_email: savedFormValues.tutor_email || "",
      profesional_derivante: savedFormValues.profesional_derivante || "",
      profesional_derivante_email: savedFormValues.profesional_derivante_email || "",
      nombreAnimal: savedFormValues.nombreAnimal || "",
      edad: savedFormValues.edad || "",
      edadUnidad: savedFormValues.edadUnidad || "años",
      sexo: savedFormValues.sexo || "",
      especie: savedFormValues.especie || "",
      raza: savedFormValues.raza || "",
      fecha: savedFormValues.fecha ? moment(savedFormValues.fecha) : moment(),
      tipo_estudio: savedFormValues.tipo_estudio || "",
      tutor_email_disabled: !savedFormValues.tutor,
      profesional_email_disabled: !savedFormValues.profesional_derivante,
    }
  })

  useEffect(() => {
    const savedFormValues = JSON.parse(sessionStorage.getItem("formValues") || "{}")
    setFormValues((prevValues) => ({
      ...prevValues,
      ...savedFormValues,
      fecha: savedFormValues.fecha ? moment(savedFormValues.fecha) : moment(),
    }))
    const selectedProfesional = professionals.find((p) => p.id === savedFormValues.profesional_derivante)
    setEmailDisabled(!!selectedProfesional?.email || !!savedFormValues.profesional_derivante_email)
  }, [professionals])

  const handleImagenesChange = useCallback(
    async (files, existingImages = []) => {
      setLoadingImages(true)
      const invalidFiles = files.filter(
        (file) => !file.type.startsWith("image/") || /\.(webp|svg|gif)$/i.test(file.name),
      )

      if (invalidFiles.length > 0) {
        message.error("Solo se permiten imágenes en formato JPG, JPEG o PNG.")
        setLoadingImages(false)
        return
      }

      const validFiles = files.filter((file) => file.type.startsWith("image/") && !/\.(webp|svg|gif)$/i.test(file.name))

      const options = {
        maxSizeMB: 50,
        maxWidthOrHeight: 2840,
        useWebWorker: true,
      }

      const compressedImagePromises = validFiles.map(async (file) => {
        try {
          const compressedFile = await imageCompression(file, options)
          return await readFileAsDataURL(compressedFile)
        } catch (error) {
          console.error("Error al comprimir la imagen:", error)
          message.error("Error al comprimir una imagen.")
        }
      })

      Promise.all(compressedImagePromises)
        .then((compressedImages) => {
          const filteredImages = compressedImages.filter((img) => img)
          addImages(filteredImages)
        })
        .then(() => setLoadingImages(false))
        .catch((error) => {
          message.error("Error al procesar las imágenes.")
          console.error("Error general:", error)
          setLoadingImages(false)
        })
    },
    [addImages],
  )

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
    })
  }

  const handleRemoveImage = (index) => {
    removeImage(index)
  }

  const handleImageClick = (image) => {
    setSelectedImage(image)
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedImage(null)
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleImagenesChange(acceptedFiles)
    },
    [handleImagenesChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    noClick: true,
  })

  useEffect(() => {
    setIsDragging(isDragActive)
  }, [isDragActive])

  const { getToken } = useAuth()

  const [formErrors, setFormErrors] = useState({})

  const [especiesIniciales, setEspeciesIniciales] = useState([
    "Canino",
    "Felino",
    "Conejo",
    "Cobayo",
    "Erizo",
    "Reptil",
    "Tortuga",
    "Chinchilla",
    "Ave",
    "Hamster",
  ])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get("access_token")
    const expiresIn = params.get("expires_in")

    if (token && expiresIn) {
      setTokenToSessionStorage("access_token", token)
      setTokenToSessionStorage("expires_in", expiresIn)
      navigate(location.pathname, { replace: true })
    }
  }, [location, navigate])

  const professionalsload = useCallback(async () => {
    setLoadingProfessionals(true)
    try {
      const token = await getToken()
      if (!token) {
        console.error("No token found, unable to generate diagnosis.")
        message.error({ content: "No se encontró el token de autenticación.", key: "upload", duration: 2 })
        return
      }
      const professionals = await getRequestingProfessionals(token)
      return professionals
    } catch (error) {
      console.error("Error loading professionals:", error)
      message.error({ content: "Error al cargar los profesionales.", key: "professionals", duration: 2 })
      return []
    } finally {
      setLoadingProfessionals(false)
    }
  }, [getToken])

  useEffect(() => {
    professionalsload().then((loadedProfessionals) => {
      const formattedProfessionals = loadedProfessionals.map((p) => ({
        id: p.id,
        name: p.name,
        email: p.email || "",
      }))
      setProfessionals(formattedProfessionals)
    })
  }, [professionalsload])

  useEffect(() => {
    const valuesToSave = {
      ...formValues,
      fecha: formValues.fecha ? formValues.fecha.toISOString() : null,
    }
    sessionStorage.setItem("formValues", JSON.stringify(valuesToSave))
  }, [formValues])

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",")
    const mimeMatch = arr[0].match(/:(.*?);/)
    if (!mimeMatch) {
      throw new Error("Invalid Data URL")
    }
    const mime = mimeMatch[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const fetchBreeds = async (query) => {
    if (!query) return setBreedSuggestions([])

    setLoadingBreeds(true)
    try {
      const customBreeds = []
      if ("indefinido".includes(query.toLowerCase())) {
        customBreeds.push({ id: "indefinido", name: "Indefinido", translatedName: "Indefinido", breedImageUrl: "" })
      }
      if ("mestizo".includes(query.toLowerCase())) {
        customBreeds.push({ id: "mestizo", name: "Mestizo", translatedName: "Mestizo", breedImageUrl: "" })
      }

      const isDog = formValues.especie === "Canino"
      const breedEndpoint = isDog ? "https://api.thedogapi.com/v1/breeds" : "https://api.thecatapi.com/v1/breeds"

      const response = await axios.get(breedEndpoint)

      const filteredBreeds = response.data.filter((breed) => breed.name.toLowerCase().includes(query.toLowerCase()))

      const breedTranslations = await Promise.all(
        filteredBreeds.map(async (breed) => {
          try {
            const wikiResponse = await axios.get(`https://es.wikipedia.org/w/api.php`, {
              params: {
                action: "query",
                list: "search",
                srsearch: breed.name,
                format: "json",
                origin: "*",
              },
            })

            const pageTitle = wikiResponse.data.query.search[0]?.title || breed.name

            const imageEndpoint = isDog
              ? `https://api.thedogapi.com/v1/images/search`
              : `https://api.thecatapi.com/v1/images/search`

            const imageResponse = await axios.get(imageEndpoint, {
              params: isDog ? { breed_ids: breed.id } : { breed_ids: breed.id },
            })

            const breedImageUrl = imageResponse.data[0]?.url || ""

            return {
              ...breed,
              translatedName: pageTitle,
              breedImageUrl,
            }
          } catch (error) {
            console.error(`Error buscando la traducción de ${breed.name}:`, error)
            return {
              ...breed,
              translatedName: breed.name,
              breedImageUrl: "",
            }
          }
        }),
      )

      setBreedSuggestions([...breedTranslations, ...customBreeds])
    } catch (error) {
      console.error("Error fetching breeds:", error)
    } finally {
      setLoadingBreeds(false)
    }
  }

  const handleRazaInputChange = (e) => {
    const { value } = e.target

    if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/.test(value)) {
      return
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      raza: value,
    }))

    setShowBreedSuggestions(true)
    fetchBreeds(value)
  }

  const handleBreedSelect = (breedName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      raza: breedName,
    }))
    setShowBreedSuggestions(false)
  }

  const handleRazaInputBlur = () => {
    setTimeout(() => {
      setShowBreedSuggestions(false)
    }, 200)
  }

  const handleClear = () => {
    setFormValues({
      tutor: "",
      tutor_email: "",
      profesional_derivante: "",
      profesional_derivante_email: "",
      nombreAnimal: "",
      edad: "",
      edadUnidad: "años",
      sexo: "",
      especie: "",
      raza: "",
      fecha: moment(),
      tipo_estudio: "",
      tutor_email_disabled: true,
      profesional_email_disabled: true,
    })
    clearImages()
    sessionStorage.removeItem("formValues")
    setTouchedFields({})
    setFormErrors({})
  }

  const handleAnalizar = async () => {
    const errors = {}
    const allFieldsTouched = {}

    if (!formValues.tutor) errors.tutor = true
    if (!formValues.nombreAnimal) errors.nombreAnimal = true
    if (!formValues.edad) errors.edad = true
    if (!formValues.sexo) errors.sexo = true
    if (!formValues.edadUnidad) errors.edadUnidad = true
    if (!formValues.especie) errors.especie = true
    if (!formValues.tipo_estudio) errors.tipo_estudio = true
    if (!formValues.fecha) errors.fecha = true

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (formValues.profesional_derivante_email && !emailRegex.test(formValues.profesional_derivante_email)) {
      errors.profesional_derivante_email = true
    }
    if (formValues.tutor_email && !emailRegex.test(formValues.tutor_email)) {
      errors.tutor_email = true
    }

    if (formValues.profesional_derivante && !formValues.profesional_derivante_email) {
      errors.profesional_derivante_email = true
    }

    Object.keys(formValues).forEach((key) => {
      allFieldsTouched[key] = true
    })

    setTouchedFields(allFieldsTouched)
    setFormErrors(errors)

    if (Object.keys(errors).length > 0) {
      message.error("Por favor, completa todos los campos obligatorios y revisa los emails antes de continuar.")
      return
    }

    try {
      const formDataToSend = new FormData()

      formDataToSend.append("tutor", formValues.tutor)
      formDataToSend.append("tutor_email", formValues.tutor_email)
      formDataToSend.append("profesional_derivante", formValues.profesional_derivante)
      formDataToSend.append("profesional_derivante_email", formValues.profesional_derivante_email)
      formDataToSend.append("nombreAnimal", formValues.nombreAnimal)
      formDataToSend.append("edad", formValues.edad)
      formDataToSend.append("edadUnidad", formValues.edadUnidad)
      formDataToSend.append("sexo", formValues.sexo)
      formDataToSend.append("especie", formValues.especie)
      formDataToSend.append("raza", formValues.raza)
      formDataToSend.append("fecha", formValues.fecha.toISOString())
      formDataToSend.append("tipo_estudio", formValues.tipo_estudio)

      imagenes.forEach((dataUrl, index) => {
        const file = dataURLtoFile(dataUrl, `imagen_${index + 1}.jpg`)
        formDataToSend.append("images", file)
      })

      message.loading({ content: "Subiendo datos...", key: "upload", duration: 0 })

      const token = await getToken()
      if (!token) {
        throw new Error("No se encontró el token de autenticación.")
      }
      for (const [key, value] of formDataToSend.entries()) {
        console.log(key, value)
      }
      console.log(formDataToSend)
      const response = await createReport(token, formDataToSend)
      sessionStorage.setItem("report", JSON.stringify(response))

      message.success({ content: "Datos subidos exitosamente!", key: "upload", duration: 2 })

      navigate("/analisis")
    } catch (error) {
      console.error("Error saving report", error)
      message.error({ content: "Error al subir los datos. Inténtalo de nuevo.", key: "upload", duration: 2 })
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name === "edad" && !/^\d{0,3}$/.test(value)) {
      return
    }

    const textFields = ["nombreAnimal", "raza", "tutor", "profesional_derivante"]
    if (textFields.includes(name) && !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/.test(value)) {
      return
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      tutor_email_disabled: name === "tutor" ? !value : prevValues.tutor_email_disabled,
      profesional_email_disabled: name === "profesional_derivante" ? !value : prevValues.profesional_email_disabled,
    }))

    setTouchedFields((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }))

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }))
  }

  const handleEspecieChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      especie: value,
      raza: "",
    }))

    setTouchedFields((prevTouched) => ({
      ...prevTouched,
      especie: true,
    }))

    setFormErrors({
      ...formErrors,
      especie: false,
    })

    if (value && !especiesIniciales.includes(value)) {
      setEspeciesIniciales((prev) => [...prev, value])
    }

    if (value) fetchBreeds("")
  }

  const handleAgeUnitChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      edadUnidad: value,
    }))

    setTouchedFields((prevTouched) => ({
      ...prevTouched,
      edadUnidad: true,
    }))
  }

  const handleEspecialistaChange = (name) => {
    let selectedProfesional = professionals.find((p) => p.name === name)

    if (!selectedProfesional) {
      const newProfesional = { id: null, name, email: "" }
      setProfessionals((prev) => [...prev, newProfesional])
      selectedProfesional = newProfesional
    }

    setFormValues((prevValues) => {
      const newValues = {
        ...prevValues,
        profesional_derivante: selectedProfesional.name,
        profesional_derivante_email: selectedProfesional.email || "",
        profesional_email_disabled: !name,
      }
      sessionStorage.setItem("formValues", JSON.stringify(newValues))
      return newValues
    })

    setTouchedFields((prevTouched) => ({
      ...prevTouched,
      profesional_derivante: true,
      profesional_derivante_email: true,
    }))

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      profesional_derivante: false,
      profesional_derivante_email: selectedProfesional && !selectedProfesional.email,
    }))

    setEmailDisabled(!!selectedProfesional?.email)
  }

  const handleEmailChange = (e) => {
    const { value } = e.target
    setFormValues((prevValues) => ({
      ...prevValues,
      profesional_derivante_email: value,
    }))

    setTouchedFields((prevTouched) => ({
      ...prevTouched,
      profesional_derivante_email: true,
    }))

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      profesional_derivante_email: formValues.profesional_derivante && !value,
    }))
  }

  const handleDeleteProfessional = async (id) => {
    try {
      const token = await getToken()
      if (!token) {
        message.error("No se encontró el token de autenticación.")
        return
      }

      await deleteRequestingProfessional(token, id)
      message.success("Profesional eliminado exitosamente")

      setProfessionals((prevProfessionals) => prevProfessionals.filter((p) => p.id !== id))

      if (formValues.profesional_derivante === professionals.find((p) => p.id === id)?.name) {
        setFormValues((prevValues) => ({
          ...prevValues,
          profesional_derivante: "",
          profesional_derivante_email: "",
          profesional_email_disabled: true,
        }))
        setEmailDisabled(false)
      }
    } catch (error) {
      console.error("Error deleting professional:", error)
      message.error("Error al eliminar el profesional. Por favor, inténtelo de nuevo.")
    }
  }

  const loadingImageStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    width: "200px",
    border: "1px solid #d9d9d9",
    borderRadius: "2px",
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newImages = Array.from(imagenes)
    const [reorderedItem] = newImages.splice(result.source.index, 1)
    newImages.splice(result.destination.index, 0, reorderedItem)

    reorderImages(newImages)
  }

  return (
    <div className="body" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragging && (
        <div className="drag-overlay">
          <p>Suelta las imágenes aquí...</p>
        </div>
      )}
      <Row className="content">
        <Col lg={13} sm={24} xs={24} className="leftcolumn">
          <Col md={13} sm={20} xs={20} className="">
            <Row className="logo-container">
              <p className="bluetitle decrease analizar-title">Analizar Paciente</p>
            </Row>
            <Row justify={"space-between"}>
              <Col className="custom-col-11-6">
                <FloatingDatePicker
                  label="Fecha"
                  value={formValues.fecha ? new Date(formValues.fecha) : null}
                  onChange={(date) => {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      fecha: date,
                    }))
                    setTouchedFields((prevTouched) => ({
                      ...prevTouched,
                      fecha: true,
                    }))
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      fecha: false,
                    }))
                  }}
                  error={formErrors.fecha}
                  touched={touchedFields.fecha}
                  required
                />
              </Col>
              <Col className="custom-col-11-6">
                <FloatingInput
                  label="Nombre Animal"
                  value={formValues.nombreAnimal}
                  onChange={handleInputChange}
                  name="nombreAnimal"
                  error={formErrors.nombreAnimal}
                  touched={touchedFields.nombreAnimal}
                  required
                />
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col className="custom-col-11-6-edad">
                <FloatingInput
                  label="Edad"
                  value={formValues.edad}
                  onChange={handleInputChange}
                  name="edad"
                  type="number"
                  error={formErrors.edad}
                  touched={touchedFields.edad}
                  required
                />
              </Col>
              <Col className="custom-col-11-6-unidad">
                <FloatingSelect
                  label="Unidad"
                  value={formValues.edadUnidad}
                  onChange={(value) => handleAgeUnitChange(value)}
                  options={[
                    { value: "días", label: "Día/s" },
                    { value: "meses", label: "Mes/es" },
                    { value: "años", label: "Año/s" },
                  ]}
                  error={formErrors.edadUnidad}
                  touched={touchedFields.edadUnidad}
                  required
                />
              </Col>
              <Col className="custom-col-11-6">
                <FloatingSelect
                  label="Especie"
                  value={formValues.especie}
                  onChange={(value) => handleEspecieChange(value)}
                  options={especiesIniciales.map((especie) => ({ value: especie, label: especie }))}
                  error={formErrors.especie}
                  touched={touchedFields.especie}
                  required
                  showSearch={true}
                  allowCustomOption={true}
                />
              </Col>
              <Col className="custom-col-11-6" style={{ position: "relative" }}>
                <FloatingInput
                  label="Raza"
                  value={formValues.raza}
                  onChange={handleRazaInputChange}
                  onBlur={handleRazaInputBlur}
                  name="raza"
                  error={formErrors.raza}
                  touched={touchedFields.raza}
                  disabled={!formValues.especie}
                />
                {showBreedSuggestions && breedSuggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {loadingBreeds ? (
                      <li>Cargando...</li>
                    ) : (
                      breedSuggestions.map((breed) => (
                        <li
                          key={breed.id}
                          onClick={() => handleBreedSelect(breed.translatedName || breed.name)}
                          className="suggestion-item"
                        >
                          <div className="suggestion-image-container">
                            {breed.breedImageUrl ? (
                              <img
                                src={breed.breedImageUrl || "/placeholder.svg"}
                                alt={breed.translatedName || breed.name}
                                className="suggestion-image"
                              />
                            ) : (
                              <span className="no-image">:(</span>
                            )}
                          </div>
                          <div className="suggestion-name">{breed.translatedName || breed.name}</div>
                        </li>
                      ))
                    )}
                  </ul>
                )}
              </Col>
              <Col className="custom-col-11-6">
                <FloatingSelect
                  label="Género"
                  value={formValues.sexo}
                  onChange={(value) => {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      sexo: value,
                    }))
                    setTouchedFields((prevTouched) => ({
                      ...prevTouched,
                      sexo: true,
                    }))
                  }}
                  options={[
                    { value: "Macho", label: "Macho" },
                    { value: "Hembra", label: "Hembra" },
                    { value: "Macho-Castrado", label: "Macho-Castrado" },
                    { value: "Hembra-Castrada", label: "Hembra-Castrada" },
                  ]}
                  error={formErrors.sexo}
                  touched={touchedFields.sexo}
                  required
                />
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col className="custom-col-11-6">
                <FloatingSelect
                  label="Profesional derivante"
                  value={formValues.profesional_derivante}
                  onChange={(value) => handleEspecialistaChange(value)}
                  options={professionals.filter((p) => p.name).map((p) => ({ value: p.name, label: p.name, id: p.id }))}
                  error={formErrors.profesional_derivante}
                  touched={touchedFields.profesional_derivante}
                  allowCustomOption={true}
                  loading={loadingProfessionals}
                  onDeleteOption={(name) => {
                    const professional = professionals.find((p) => p.name === name)
                    if (professional) {
                      handleDeleteProfessional(professional.id)
                    }
                  }}
                />
              </Col>
              <Col className="custom-col-11-6">
                <FloatingInput
                  label="Email del profesional"
                  value={formValues.profesional_derivante_email}
                  onChange={handleEmailChange}
                  name="profesional_derivante_email"
                  error={formErrors.profesional_derivante_email}
                  touched={touchedFields.profesional_derivante_email}
                  disabled={formValues.profesional_email_disabled || emailDisabled}
                  required={!!formValues.profesional_derivante}
                />
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col className="custom-col-11-6">
                <FloatingInput
                  label="Tutor"
                  value={formValues.tutor}
                  onChange={handleInputChange}
                  name="tutor"
                  error={formErrors.tutor}
                  touched={touchedFields.tutor}
                  required
                />
              </Col>
              <Col className="custom-col-11-6">
                <FloatingInput
                  label="Email del tutor"
                  value={formValues.tutor_email}
                  onChange={handleInputChange}
                  name="tutor_email"
                  error={formErrors.tutor_email}
                  touched={touchedFields.tutor_email}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FloatingSelect
                  label="Tipo de estudio"
                  value={formValues.tipo_estudio}
                  onChange={(value) => {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      tipo_estudio: value,
                    }))
                    setTouchedFields((prevTouched) => ({
                      ...prevTouched,
                      tipo_estudio: true,
                    }))
                  }}
                  options={[
                    { value: "Radiografía", label: "Radiografía" },
                    { value: "Ecografía", label: "Ecografía" },
                  ]}
                  error={formErrors.tipo_estudio}
                  touched={touchedFields.tipo_estudio}
                  required
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="upload-button-wrapper">
                  <label htmlFor="upload-input" className="minimalist-button minimalist-button-primary">
                    <UploadCloud size={20} />
                    Agregar imágenes
                  </label>
                  <input
                    id="upload-input"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleImagenesChange(Array.from(e.target.files))}
                    style={{ display: "none" }}
                  />
                </div>
              </Col>
            </Row>
            <Row justify={"space-between"} className="button-container3">
              <button type="button" className="minimalist-button2 redesigned-danger-button" onClick={handleClear}>
                <Trash2 size={16} />
                Limpiar
              </button>
              <button type="button" className="minimalist-button2 redesigned-secondary-button" onClick={handleAnalizar}>
                Continuar
                <ArrowRight size={16} />
              </button>
            </Row>
          </Col>
        </Col>
        <Col lg={11} sm={24} xs={24} className="rightcolumn">
          <div className="rightside-container4">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="imageList" direction="horizontal">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="image-grid">
                    {imagenes.map((imagen, index) => (
                      <Draggable key={`image-${index}`} draggableId={`image-${index}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="image-preview"
                          >
                            <img
                              src={imagen || "/placeholder.svg"}
                              alt={`Imagen ${index + 1}`}
                              className="uploaded-image"
                              onClick={() => handleImageClick(imagen)}
                            />
                            <Button className="remove-button" onClick={() => handleRemoveImage(index)}>
                              <DeleteOutlined />
                            </Button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    {loadingImages && (
                      <div className="image-preview loading-image" style={loadingImageStyle}>
                        <Spin size="large" />
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>
      </Row>
      <Modal visible={isModalVisible} footer={null} onCancel={handleModalClose}>
        <img src={selectedImage || "/placeholder.svg"} alt="Imagen ampliada" style={{ width: "100%" }} />
      </Modal>
    </div>
  )
}

export default AnalizarPaciente

