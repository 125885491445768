import React, { useState, useEffect, useCallback, useRef } from "react"
import { Input, Button, message, Modal, Row, AutoComplete } from "antd"
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons"
import FloatingSelect from "./FloatingSelect"
import {
  getSistemas,
  getEstructuras,
  getSubestructuras,
  getSubsubestructuras,
  getSubsubsubestructuras,
  getProblemas,
  getDescripcion,
  tieneSubestructuras,
  getDescriptionSuggestions,
} from "../utils/observacionDataExtractor"
import { ModalContent } from "./ModalContent"
import MicrophoneButton from "../components/MicrophoneButton"
import "../assets/styles/SeccionPaciente.css"

const { TextArea } = Input

export const ObservationsSection = ({ observations, setObservations }) => {
  const [selectedSistema, setSelectedSistema] = useState("")
  const [selectedEstructura, setSelectedEstructura] = useState("")
  const [selectedSubestructura, setSelectedSubestructura] = useState("")
  const [selectedSubsubestructura, setSelectedSubsubestructura] = useState("")
  const [selectedSubsubsubestructura, setSelectedSubsubsubestructura] = useState("")
  const [selectedProblem, setSelectedProblem] = useState("")
  const [problemDescription, setProblemDescription] = useState("")

  const [sistemas, setSistemas] = useState([])
  const [estructuras, setEstructuras] = useState([])
  const [subestructuras, setSubestructuras] = useState([])
  const [subsubestructuras, setSubsubestructuras] = useState([])
  const [subsubsubestructuras, setSubsubsubestructuras] = useState([])
  const [problemas, setProblemas] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [modalInfo, setModalInfo] = useState({ visible: false, subestructura: "", problema: "" })
  const [modalType, setModalType] = useState("")
  const [selectedOptions, setSelectedOptions] = useState({})
  const [hasKeyword, setHasKeyword] = useState(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState(true)
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([])
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(true)
  const [originalDescription, setOriginalDescription] = useState("")
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(false)
  const [isSecondMicrophoneActive, setIsSecondMicrophoneActive] = useState(false) // Added state for the second microphone

  const [currentTranscript, setCurrentTranscript] = useState("")
  const isTranscribingRef = useRef(false)
  const [secondCurrentTranscript, setSecondCurrentTranscript] = useState("")
  const isSecondTranscribingRef = useRef(false)
  const descriptionTextAreaRef = useRef(null) // Added ref for TextArea

  const checkForKeywords = useCallback((text) => {
    const keywords = ["[COSTILLAS]", "[ESTERNEBRAS]", "[CARTILAGOS]", "[VERTEBRAS]", "[ESPACIOS]", "[ARTICULACIONES]"]
    for (const keyword of keywords) {
      if (text.includes(keyword)) {
        return keyword.replace(/[[\]]/g, "")
      }
    }
    return null
  }, [])

  const checkForUnresolvedKeywords = (text) => {
    const keywords = ["[COSTILLAS]", "[ESTERNEBRAS]", "[CARTILAGOS]", "[VERTEBRAS]", "[ESPACIOS]", "[ARTICULACIONES]"]
    return keywords.filter((keyword) => text.includes(keyword))
  }

  const handleTranscriptUpdate = useCallback((transcript) => {
    setCurrentTranscript(transcript)
    isTranscribingRef.current = true
  }, [])

  const handleTranscriptComplete = useCallback(
    (finalTranscript) => {
      setObservations((prevObservations) => {
        const updatedObservations = [...prevObservations]
        if (isTranscribingRef.current) {
          // If the last observation is the current transcript, replace it
          if (
            updatedObservations.length > 0 &&
            updatedObservations[updatedObservations.length - 1] === currentTranscript
          ) {
            updatedObservations[updatedObservations.length - 1] = finalTranscript
          } else {
            // Otherwise, add the final transcript as a new observation
            updatedObservations.push(finalTranscript)
          }
        }
        return updatedObservations
      })
      setCurrentTranscript("")
      isTranscribingRef.current = false
    },
    [currentTranscript, setObservations],
  )

  const handleTextAreaChange = (e) => {
    setCurrentTranscript("")
    const newObservations = e.target.value.split("\n")
    setObservations(newObservations)
  }

  const handleTextAreaBlur = () => {
    setObservations((prevObservations) => prevObservations.map((obs) => obs.trim()))
  }

  useEffect(() => {
    setSistemas(getSistemas())
  }, [])

  const resetDependentFields = (level) => {
    if (level <= 1) {
      setSelectedEstructura("")
      setEstructuras([])
    }
    if (level <= 2) {
      setSelectedSubestructura("")
      setSubestructuras([])
    }
    if (level <= 3) {
      setSelectedSubsubestructura("")
      setSubsubestructuras([])
    }
    if (level <= 4) {
      setSelectedSubsubsubestructura("")
      setSubsubsubestructuras([])
    }
    setSelectedProblem("")
    if (level <= 2) {
      setProblemas([])
    }
    setProblemDescription("")
    setSelectedOptions({})
    setHasKeyword(false)
    setModalType("")
    setIsDescriptionValid(true)
    setIsDescriptionEditable(true)
    setOriginalDescription("")
  }

  useEffect(() => {
    if (selectedSistema) {
      const newEstructuras = getEstructuras(selectedSistema)
      setEstructuras(newEstructuras)
      if (!isLoaded) {
        resetDependentFields(1)
      }
    } else {
      resetDependentFields(1)
    }
  }, [selectedSistema, isLoaded])

  useEffect(() => {
    if (selectedSistema && selectedEstructura) {
      const newSubestructuras = getSubestructuras(selectedSistema, selectedEstructura)
      setSubestructuras(newSubestructuras)
      if (!isLoaded) {
        resetDependentFields(2)
      }

      if (!tieneSubestructuras(selectedSistema, selectedEstructura)) {
        const newProblemas = getProblemas(selectedSistema, selectedEstructura)
        setProblemas(newProblemas)
      } else {
        setProblemas([])
      }
    } else {
      resetDependentFields(2)
    }
  }, [selectedSistema, selectedEstructura, isLoaded])

  useEffect(() => {
    if (selectedSistema && selectedEstructura && selectedSubestructura) {
      const newSubsubestructuras = getSubsubestructuras(selectedSistema, selectedEstructura, selectedSubestructura)
      setSubsubestructuras(newSubsubestructuras)

      const newProblemas = getProblemas(selectedSistema, selectedEstructura, selectedSubestructura)
      setProblemas(newProblemas)

      if (!isLoaded) {
        resetDependentFields(3)
      }
    } else {
      resetDependentFields(3)
    }
  }, [selectedSistema, selectedEstructura, selectedSubestructura, isLoaded])

  useEffect(() => {
    if (selectedSistema && selectedEstructura && selectedSubestructura && selectedSubsubestructura) {
      const newSubsubsubestructuras = getSubsubsubestructuras(
        selectedSistema,
        selectedEstructura,
        selectedSubestructura,
        selectedSubsubestructura,
      )
      setSubsubsubestructuras(newSubsubsubestructuras)
      if (!isLoaded) {
        resetDependentFields(4)
      }

      if (newSubsubsubestructuras.length === 0) {
        const newProblemas = getProblemas(
          selectedSistema,
          selectedEstructura,
          selectedSubestructura,
          selectedSubsubestructura,
        )
        setProblemas(newProblemas)
      } else {
        setProblemas([])
      }
    } else {
      resetDependentFields(4)
    }
  }, [selectedSistema, selectedEstructura, selectedSubestructura, selectedSubsubestructura, isLoaded])

  useEffect(() => {
    if (
      selectedSistema &&
      selectedEstructura &&
      selectedSubestructura &&
      selectedSubsubestructura &&
      selectedSubsubsubestructura
    ) {
      const newProblemas = getProblemas(
        selectedSistema,
        selectedEstructura,
        selectedSubestructura,
        selectedSubsubestructura,
        selectedSubsubsubestructura,
      )
      setProblemas(newProblemas)
      if (!isLoaded) {
        setSelectedProblem("")
        setProblemDescription("")
      }
    }
  }, [
    selectedSistema,
    selectedEstructura,
    selectedSubestructura,
    selectedSubsubestructura,
    selectedSubsubsubestructura,
    isLoaded,
  ])

  const updateDescriptionWithSelection = useCallback((type, options) => {
    setProblemDescription((prevDesc) => {
      const keyword = `[${type.toUpperCase()}]`
      let formattedOptions = ""

      if (options && options.length > 0) {
        const singularForms = {
          COSTILLAS: "costilla",
          ESTERNEBRAS: "esternebra",
          CARTILAGOS: "cartílago",
          VERTEBRAS: "vértebra",
          ESPACIOS: "",
          ARTICULACIONES: "articulacion",
        }

        const pluralForms = {
          COSTILLAS: "costillas",
          ESTERNEBRAS: "esternebras",
          CARTILAGOS: "cartílagos",
          VERTEBRAS: "vértebras",
          ESPACIOS: "",
          ARTICULACIONES: "articulaciones",
        }

        const regex = new RegExp(`${pluralForms[type]}\\s+([\\d,\\s]+)`, "i")
        prevDesc = prevDesc.replace(regex, "")

        const extractNumber = (str) => {
          const match = str.match(/\d+/)
          return match ? Number.parseInt(match[0], 10) : 0
        }

        const uniqueSortedOptions = [...new Set(options)].sort((a, b) => {
          const order = ["C", "T", "L", "S", "Co"]
          const aPrefix = a.charAt(0)
          const bPrefix = b.charAt(0)
          const aIndex = order.indexOf(aPrefix)
          const bIndex = order.indexOf(bPrefix)
      
          if (aIndex !== bIndex) {
            return aIndex - bIndex
          }
      
          const aNum = extractNumber(a)
          const bNum = extractNumber(b)
      
          if (aNum !== bNum) {
            return aNum - bNum
          }
      
          return a.localeCompare(b)
        })

        if (uniqueSortedOptions.length === 1) {
          formattedOptions = `${singularForms[type]} ${uniqueSortedOptions[0]}`
        } else {
          formattedOptions = `${pluralForms[type]} ${uniqueSortedOptions.join(", ")}`
        }
      }

      if (prevDesc.includes(keyword)) {
        return prevDesc.replace(keyword, formattedOptions)
      } else {
        return `${prevDesc.trim()} ${formattedOptions}`.trim()
      }
    })
  }, [])

  const handleKeywordDetection = useCallback(
    (description) => {
      const detectedKeyword = checkForKeywords(description)
      if (detectedKeyword) {
        setModalType(detectedKeyword)
        setHasKeyword(true)

        if (!selectedOptions[detectedKeyword] || selectedOptions[detectedKeyword].length === 0) {
          setModalInfo({ visible: true, subestructura: selectedSubestructura, problema: selectedProblem })
        } else {
          updateDescriptionWithSelection(detectedKeyword, selectedOptions[detectedKeyword])
        }
      } else {
        setHasKeyword(false)
        setModalType("")
      }
    },
    [checkForKeywords, selectedOptions, selectedSubestructura, selectedProblem, updateDescriptionWithSelection],
  )

  useEffect(() => {
    if (selectedProblem) {
      const description = getDescripcion(
        selectedSistema,
        selectedEstructura,
        selectedSubestructura,
        selectedSubsubestructura,
        selectedSubsubsubestructura,
        selectedProblem,
      )
      setProblemDescription(description)
      setOriginalDescription(description)
      handleKeywordDetection(description)
      setIsDescriptionEditable(false)
    } else {
      setIsDescriptionEditable(true)
    }
  }, [
    selectedSistema,
    selectedEstructura,
    selectedSubestructura,
    selectedSubsubestructura,
    selectedSubsubsubestructura,
    selectedProblem,
    handleKeywordDetection,
  ])

  useEffect(() => {
    const savedData = JSON.parse(sessionStorage.getItem("observationsForm") || "{}")
    if (Object.keys(savedData).length > 0) {
      setSelectedSistema(savedData.selectedSistema || "")
      setSelectedEstructura(savedData.selectedEstructura || "")
      setSelectedSubestructura(savedData.selectedSubestructura || "")
      setSelectedSubsubestructura(savedData.selectedSubsubestructura || "")
      setSelectedSubsubsubestructura(savedData.selectedSubsubsubestructura || "")
      setSelectedProblem(savedData.selectedProblem || "")
      setProblemDescription(savedData.problemDescription || "")
      setIsDescriptionEditable(!savedData.selectedProblem)
      setObservations(savedData.observations || [])
      setSelectedOptions(savedData.selectedOptions || {})
      setModalType(savedData.modalType || "")
      setHasKeyword(savedData.hasKeyword || false)
      setIsDescriptionValid(savedData.isDescriptionValid || true)
      setOriginalDescription(savedData.originalDescription || "")

      if (savedData.selectedSistema) {
        setEstructuras(getEstructuras(savedData.selectedSistema))
        if (savedData.selectedEstructura) {
          setSubestructuras(getSubestructuras(savedData.selectedSistema, savedData.selectedEstructura))
          if (savedData.selectedSubestructura) {
            setSubsubestructuras(
              getSubsubestructuras(
                savedData.selectedSistema,
                savedData.selectedEstructura,
                savedData.selectedSubestructura,
              ),
            )
            if (savedData.selectedSubsubestructura) {
              setSubsubsubestructuras(
                getSubsubsubestructuras(
                  savedData.selectedSistema,
                  savedData.selectedEstructura,
                  savedData.selectedSubestructura,
                  savedData.selectedSubsubestructura,
                ),
              )
            }
          }
        }
        if (
          !tieneSubestructuras(
            savedData.selectedSistema,
            savedData.selectedEstructura,
            savedData.selectedSubestructura,
            savedData.selectedSubsubestructura,
          )
        ) {
          setProblemas(
            getProblemas(
              savedData.selectedSistema,
              savedData.selectedEstructura,
              savedData.selectedSubestructura,
              savedData.selectedSubsubestructura,
              savedData.selectedSubsubsubestructura,
            ),
          )
        }
      }
    }
    setIsLoaded(true)
  }, [setObservations])

  useEffect(() => {
    if (isLoaded && (selectedSistema || observations.length > 0)) {
      const dataToSave = {
        selectedSistema,
        selectedEstructura,
        selectedSubestructura,
        selectedSubsubestructura,
        selectedSubsubsubestructura,
        selectedProblem,
        problemDescription,
        observations,
        selectedOptions,
        modalType,
        hasKeyword,
        isDescriptionValid,
        originalDescription,
        isDescriptionEditable,
      }
      sessionStorage.setItem("observationsForm", JSON.stringify(dataToSave))
    }
  }, [
    isLoaded,
    selectedSistema,
    selectedEstructura,
    selectedSubestructura,
    selectedSubsubestructura,
    selectedSubsubsubestructura,
    selectedProblem,
    problemDescription,
    observations,
    selectedOptions,
    modalType,
    hasKeyword,
    isDescriptionValid,
    originalDescription,
    isDescriptionEditable,
  ])

  useEffect(() => {
    const suggestions = getDescriptionSuggestions(
      selectedSistema,
      selectedEstructura,
      selectedSubestructura,
      selectedSubsubestructura,
      selectedSubsubsubestructura,
      selectedProblem,
      problemDescription,
    )
    setDescriptionSuggestions(suggestions)
  }, [
    selectedSistema,
    selectedEstructura,
    selectedSubestructura,
    selectedSubsubestructura,
    selectedSubsubsubestructura,
    selectedProblem,
    problemDescription,
  ])

  const handleAddObservation = () => {
    if (!problemDescription || problemDescription.trim() === "") {
      message.error("Por favor, complete la descripción del problema antes de agregar.")
      return
    }

    if (hasKeyword) {
      const unresolvedKeywords = checkForUnresolvedKeywords(problemDescription)
      if (unresolvedKeywords.length > 0) {
        setIsDescriptionValid(false)
        message.error(
          `Por favor, complete la descripción del problema y resuelva los siguientes campos: ${unresolvedKeywords.join(", ")}`,
        )
        return
      }
    }

    let observation = problemDescription.trim()

    if (!observation.endsWith(".")) {
      observation += "."
    }
    observation = observation.replace(/^\./, "")

    setObservations((prevObservations) => {
      const cleanedObservations = prevObservations.filter((obs) => obs.trim() !== "")
      return [...cleanedObservations, observation]
    })

    setSelectedProblem("")
    setProblemDescription("")
    setHasKeyword(false)
    setSelectedOptions({})
    setModalType("")
    setIsDescriptionValid(true)
    setIsDescriptionEditable(true)
    setOriginalDescription("")
  }

  const handleModalCancel = useCallback(() => {
    setModalInfo({ visible: false, subestructura: "", problema: "" })
  }, [])

  const normalizeText = (text) =>
    text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")

  const handleDescriptionChange = useCallback(
    (value) => {
      setProblemDescription(value);
      setSecondCurrentTranscript(""); // Limpia el transcript actual al escribir
      handleKeywordDetection(value);
      const suggestions = getDescriptionSuggestions(
        selectedSistema,
        selectedEstructura,
        selectedSubestructura,
        selectedSubsubestructura,
        selectedSubsubsubestructura,
        selectedProblem,
        value
      );
      setDescriptionSuggestions(suggestions);
    },
    [
      selectedSistema,
      selectedEstructura,
      selectedSubestructura,
      selectedSubsubestructura,
      selectedSubsubsubestructura,
      selectedProblem,
      handleKeywordDetection,
    ]
  );

  const handleModalOptionsChange = useCallback(
    (options) => {
      setSelectedOptions((prev) => {
        const newOptions = { ...prev, [modalType]: options }
        updateDescriptionWithSelection(modalType, options)
        return newOptions
      })
    },
    [modalType, updateDescriptionWithSelection],
  )

  const handleSecondTranscriptUpdate = useCallback((transcript) => {
    setSecondCurrentTranscript(transcript)
    isSecondTranscribingRef.current = true
  }, [])

  const handleSecondTranscriptComplete = useCallback(
    (finalTranscript) => {
      setProblemDescription((prevDescription) => {
        if (isSecondTranscribingRef.current) {
          const newDescription =
            prevDescription === secondCurrentTranscript
              ? finalTranscript.trim()
              : (prevDescription + " " + finalTranscript).trim()

          // Set timeout to ensure the state has been updated
          setTimeout(() => {
            if (descriptionTextAreaRef.current) {
              const textArea = descriptionTextAreaRef.current.resizableTextArea.textArea
              textArea.focus()
              textArea.setSelectionRange(newDescription.length, newDescription.length)
            }
          }, 0)

          return newDescription
        }
        return prevDescription
      })
      setSecondCurrentTranscript("")
      isSecondTranscribingRef.current = false
    },
    [secondCurrentTranscript],
  )

  return (
    <>
      <FloatingSelect
        label="Sistema"
        value={selectedSistema}
        onChange={(value) => {
          setSelectedSistema(value)
          resetDependentFields(1)
        }}
        options={sistemas}
        fullWidth
      />

      <FloatingSelect
        label="Seleccionar estructura"
        value={selectedEstructura}
        onChange={(value) => {
          setSelectedEstructura(value)
          resetDependentFields(2)
        }}
        options={estructuras}
        disabled={!selectedSistema}
        fullWidth
      />

      <FloatingSelect
        label="Seleccionar subestructura"
        value={selectedSubestructura}
        onChange={(value) => {
          setSelectedSubestructura(value)
          resetDependentFields(3)
        }}
        options={subestructuras}
        disabled={!selectedEstructura || subestructuras.length === 0}
        fullWidth
      />

      <FloatingSelect
        label="Seleccionar subsubestructura"
        value={selectedSubsubestructura}
        onChange={(value) => {
          setSelectedSubsubestructura(value)
          resetDependentFields(4)
        }}
        options={subsubestructuras}
        disabled={!selectedSubestructura || subsubestructuras.length === 0}
        fullWidth
      />

      <FloatingSelect
        label="Seleccionar subsubsubestructura"
        value={selectedSubsubsubestructura}
        onChange={(value) => {
          setSelectedSubsubsubestructura(value)
          setSelectedProblem("")
          setProblemDescription("")
        }}
        options={subsubsubestructuras}
        disabled={!selectedSubsubestructura || subsubsubestructuras.length === 0}
        fullWidth
      />

      <FloatingSelect
        label="Seleccionar problema"
        value={selectedProblem}
        onChange={(value) => {
          setSelectedProblem(value)
        }}
        options={problemas}
        disabled={problemas.length === 0}
        fullWidth
      />
      <div style={{ position: "relative" }}>
        <AutoComplete
          placeholder="Descripción del problema"
          value={problemDescription + (secondCurrentTranscript ? ` ${secondCurrentTranscript}` : "")}
          onChange={handleDescriptionChange}
          options={descriptionSuggestions.map((suggestion, index) => ({
            value: suggestion,
            key: `${suggestion}-${index}`,
          }))}
          filterOption={(inputValue, option) => normalizeText(option?.value || "").includes(normalizeText(inputValue))}
          onSelect={(value) => {
            handleDescriptionChange(value)
          }}
          style={{ width: "100%" }} // Added style for positioning
        >
          <TextArea
            ref={descriptionTextAreaRef} // Added ref to TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{
              width: "100%",
              borderColor: !isDescriptionValid ? "red" : undefined,
            }}
          />
        </AutoComplete>
        <MicrophoneButton
          onTranscriptUpdate={handleSecondTranscriptUpdate}
          onTranscriptComplete={handleSecondTranscriptComplete}
          isActive={isSecondMicrophoneActive}
          setIsActive={setIsSecondMicrophoneActive}
        />
      </div>
      {!isDescriptionValid && (
        <div style={{ color: "red", marginBottom: "10px" }}>
          {hasKeyword ? "Descripción incompleta." : "Descripción incompleta."}
        </div>
      )}
      <Row justify="space-between" className="margtop">
        {hasKeyword && (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setModalInfo({ visible: true, subestructura: selectedSubestructura, problema: selectedProblem })
            }}
            style={{ marginBottom: "10px" }}
          >
            Reelegir
          </Button>
        )}

        <Button icon={<PlusCircleOutlined />} onClick={handleAddObservation} style={{ float: "right", marginBottom: "20px",}}>
          Agregar
        </Button>
      </Row>

      <div style={{ position: "relative" }}>
        <TextArea
          placeholder="Observaciones agregadas"
          value={observations.join("\n") + (currentTranscript ? `\n${currentTranscript}` : "")}
          rows={4}
          onChange={handleTextAreaChange}
          onBlur={handleTextAreaBlur}
          className="custom-textarea"
          style={{
            paddingRight: "30px",
          }}
        />
        <MicrophoneButton
          onTranscriptUpdate={handleTranscriptUpdate}
          onTranscriptComplete={handleTranscriptComplete}
          isActive={isMicrophoneActive}
          setIsActive={setIsMicrophoneActive}
        />
      </div>

      <Modal
        title={`Opciones para ${modalType}`}
        visible={modalInfo.visible}
        onOk={() => {
          handleModalOptionsChange(selectedOptions[modalType] || [])
          setModalInfo({ visible: false, subestructura: "", problema: "" })
        }}
        onCancel={handleModalCancel}
        destroyOnClose={true}
        cancelText="Cerrar"
      >
        <ModalContent
          type={modalType}
          selectedOptions={selectedOptions[modalType] || []}
          setSelectedOptions={(options) => {
            setSelectedOptions((prev) => ({ ...prev, [modalType]: options }))
          }}
          subestructura={modalInfo.subestructura}
          problema={modalInfo.problema}
        />
      </Modal>
    </>
  )
}