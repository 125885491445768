const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const getRequestingProfessionals = async (token) => {
    const response = await fetch(`${REACT_APP_BFF_URL}/requesting-professionals`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Error occurred while fetching requesting professionals.');
    }

    const data = await response.json();
    return data;
};

export const deleteRequestingProfessional = async (token, professionalId) => {
    const response = await fetch(`${REACT_APP_BFF_URL}/requesting-professionals/${professionalId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Error occurred while deleting requesting professional.');
    }

    return {message: 'Requesting professional deleted successfully'};
};