import { createContext, useState, useEffect } from "react"

// Create the context
export const ImageContext = createContext()

// Create the provider component
export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([])

  // Function to load images from sessionStorage
  const loadImagesFromSessionStorage = () => {
    const storedImages = JSON.parse(sessionStorage.getItem("images")) || []
    return storedImages.slice(0, 10) // Ensure a max of 20 images
  }

  // Initialize images when the component mounts
  useEffect(() => {
    const storedImages = loadImagesFromSessionStorage()
    setImages(storedImages) // Set the images state after loading from sessionStorage
  }, [])

  // Function to save images to sessionStorage
  const saveImagesToSessionStorage = (newImages) => {
    sessionStorage.setItem("images", JSON.stringify(newImages))
  }

  // Function to add new images
  const addImages = (newImages) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages, ...newImages].slice(0, 10) // Limit to 20 images
      saveImagesToSessionStorage(updatedImages)
      return updatedImages
    })
  }

  // Function to remove an image by index
  const removeImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index)
      saveImagesToSessionStorage(updatedImages)
      return updatedImages
    })
  }

  // Function to clear all images
  const clearImages = () => {
    setImages([])
    sessionStorage.removeItem("images")
  }

  // Add this new function to the ImageProvider component
  const reorderImages = (newOrder) => {
    setImages(newOrder)
    saveImagesToSessionStorage(newOrder)
  }

  return (
    <ImageContext.Provider value={{ images, addImages, removeImage, clearImages, setImages, reorderImages }}>
      {children}
    </ImageContext.Provider>
  )
}

