import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Col, Collapse, Input, Popconfirm, Row} from 'antd';
import {useNavigate} from 'react-router-dom';
import {askVertex} from '../services/VertexServices';
import {RequestSection} from '../components/RequestSection';
import {ObservationsSection} from '../components/ObservationsSection';
import {fixEncodingIssues} from '../utils/encoding';
import {useAuth} from "../contexts/authContext";
import InformeModal from './InformeModal';
import {ImageContext} from "../contexts/imageContext/imageContext";

const { Panel } = Collapse;
const { TextArea } = Input;

export const RightColumn = ({
  regenerateCount,
  setRegenerateCount,
  setIsModalVisible,
  width,
}) => {
  const [selectedStructure, setSelectedStructure] = useState('');
  const [selectedSubstructure, setSelectedSubstructure] = useState('');
  const [selectedIncidences, setSelectedIncidences] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState('');
  const [selectedStructure2, setSelectedStructure2] = useState('');
  const [selectedSubstructure2, setSelectedSubstructure2] = useState('');
  const [selectedSubsubestructura, setSelectedSubsubestructura] = useState('');
  const [selectedSubsubsubestructura, setSelectedSubsubsubestructura] = useState('');
  const [problem, setProblem] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [isObservationsEnabled, setIsObservationsEnabled] = useState(false);
  const [observations, setObservations] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isReadyToGenerate, setIsReadyToGenerate] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [redaccion, setRedaccion] = useState('');
  const [alreadyGenerated, setAlreadyGenerated] = useState(false);
  const [isModalVisible, setIsModalVisibleInternal] = useState(false);
  const [vertexResponse, setVertexResponse] = useState({});

  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  const { getToken } = useAuth();

  const {clearImages} = useContext(ImageContext);

  const loadDataFromLocalStorage = useCallback(() => {
    const savedData = JSON.parse(sessionStorage.getItem('formData2') || '{}');
    if (Object.keys(savedData).length > 0) {
      setSelectedStructure(savedData.selectedStructure || '');
      setSelectedSubstructure(savedData.selectedSubstructure || '');
      setSelectedIncidences(savedData.selectedIncidences || []);
      setSelectedSystem(savedData.selectedSystem || '');
      setSelectedStructure2(savedData.selectedStructure2 || '');
      setSelectedSubstructure2(savedData.selectedSubstructure2 || '');
      setSelectedSubsubestructura(savedData.selectedSubsubestructura || '');
      setSelectedSubsubsubestructura(savedData.selectedSubsubsubestructura || '');
      setProblem(savedData.problem || '');
      setProblemDescription(savedData.problemDescription || '');
      setObservations(savedData.observations || []);
      setRedaccion(savedData.redaccion || '');
    }
    const vertexResponseData = JSON.parse(sessionStorage.getItem('vertexResponse') || '{}');
    if (Object.keys(vertexResponseData).length > 0) {
      setVertexResponse(vertexResponseData);
      setAlreadyGenerated(true);
      setIsGenerated(true);
    }
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      loadDataFromLocalStorage();
    }
  }, [loadDataFromLocalStorage, isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      const formData2 = {
        selectedStructure,
        selectedSubstructure,
        selectedIncidences,
        selectedSystem,
        selectedStructure2,
        selectedSubstructure2,
        selectedSubsubestructura,
        selectedSubsubsubestructura,
        problem,
        problemDescription,
        observations,
        redaccion,
      };
      const currentFormData = JSON.parse(sessionStorage.getItem('formData2') || '{}');
      if (JSON.stringify(formData2) !== JSON.stringify(currentFormData)) {
        sessionStorage.setItem('formData2', JSON.stringify(formData2));
      }
    }
  }, [
    isLoaded,
    selectedStructure,
    selectedSubstructure,
    selectedIncidences,
    selectedSystem,
    selectedStructure2,
    selectedSubstructure2,
    selectedSubsubestructura,
    selectedSubsubsubestructura,
    problem,
    problemDescription,
    observations,
    redaccion,
  ]);

  useEffect(() => {
    if (isLoaded && Object.keys(vertexResponse).length > 0) {
      sessionStorage.setItem('vertexResponse', JSON.stringify(vertexResponse));
    }
  }, [isLoaded, vertexResponse]);

  useEffect(() => {
    if (redaccion.trim() !== '' && observations.length > 0 && observations[0].trim() !== '') {
      setIsReadyToGenerate(true);
    } else {
      setIsReadyToGenerate(false);
    }
  }, [redaccion, observations]);

  useEffect(() => {
    if (redaccion.length > 0) {
      setIsObservationsEnabled(true);
    } else {
      setIsObservationsEnabled(true);
    }
  }, [redaccion]);

  const processDiagnosisText = (text) => {
    return text
        .split('\n')
        .map(line => line.trim().replace(/^[•*]\s*/, '').trim()) // Remove bullet points and asterisks at the start
        .filter(line => line.length > 0)
        .map(line => `• ${line}`) // Add bullet point to the start of each line
        .join('\n');
  };

  const regenerateDiagnosis = async () => {
    if (regenerateCount >= 3) return;
    setIsGenerating(true);
    setRegenerateCount(regenerateCount + 1);

    const formData2 = JSON.parse(sessionStorage.getItem('formData2') || '{}');
    const formValues = JSON.parse(sessionStorage.getItem('formValues') || '{}');

    const observations = formData2.observations || [];
    const prompt = `
    paciente: ${formValues.nombreAnimal || 'Indefinido'}. Especie: ${formValues.especie || 'Indefinida'}. Propietario: ${formValues.tutor || 'Indefinido'}. Edad: ${formValues.edad || 'Indefinida'} ${formValues.edadUnidad || ''}. Raza: ${formValues.raza || 'Indefinida'}. Sexo: ${formValues.sexo || 'Indefinido'}. Solicitud: ${formData2.redaccion || 'Indefinida'} observaciones: ${observations.join(' ')}
  `.trim();
    try {
      const token = await getToken();
      if (!token) {
        console.error("No token found, unable to generate diagnosis.");
        return;
      }
      const response = await askVertex(prompt, token);
      const responseText = response.generatedDiagnosis;
      const fixedResponse = fixEncodingIssues(responseText.trim());
      const processedResponse = processDiagnosisText(fixedResponse);
      
      setVertexResponse({
        aiGenerated: response.aiGenerated,
        prompt: response.prompt,
        generatedDiagnosis: response.generatedDiagnosis,
        finalDiagnosis: processedResponse,
        modified: response.modified,
        model: response.model
      });

      setIsGenerating(false);
      setIsGenerated(true);
      setAlreadyGenerated(true);
    } catch (error) {
      console.error("Error generating diagnosis:", error);
      setIsGenerating(false);
    }
  };

  const nuevoInforme = () => {
    sessionStorage.removeItem('formData2');
    sessionStorage.removeItem('formValues');
    sessionStorage.removeItem('vertexResponse');
    sessionStorage.removeItem('regenerateCount');
    sessionStorage.removeItem('diagnostico');
    sessionStorage.removeItem('observaciones');
    sessionStorage.removeItem('observationsForm');
    sessionStorage.removeItem('solicitud');
    sessionStorage.removeItem('report');
    clearImages();
    setAlreadyGenerated(false);
    setIsGenerated(false);
    setVertexResponse({});
    navigate('/dashboard')
  }

  const toggleModal = () => {
    setIsModalVisibleInternal(true);
    setIsModalVisible(true);
  };

  const updateStateFromModal = (field, value) => {
    switch (field) {
        case 'solicitud':
            if (value !== redaccion) setRedaccion(value);
            break;
        case 'observaciones':
            if (JSON.stringify(value) !== JSON.stringify(observations)) setObservations(value);
            break;
        case 'diagnostico':
            if (value !== vertexResponse.finalDiagnosis) {
                setVertexResponse(prevState => ({
                    ...prevState,
                    finalDiagnosis: value,
                    modified: true
                }));
            }
            break;
        default:
            console.warn(`Unhandled field in updateStateFromModal: ${field}`);
    }
  };

  return (
    <Col className="columna-der" style={{ width: `${width}px` }}>
      <Row justify={'center'} className="center">
        <Col span={20}>
          <Row className="input-row">
            <Collapse
              defaultActiveKey={['1']}
              expandIconPosition="end"
              className='collapse'
            >
              <Panel
                header={<span className="generating">Solicitud:</span>}
                key="1"
              >
                <div style={{ marginTop: '16px' }}>
                  <RequestSection
                    selectedStructure={selectedStructure}
                    setSelectedStructure={setSelectedStructure}
                    selectedSubstructure={selectedSubstructure}
                    setSelectedSubstructure={setSelectedSubstructure}
                    selectedIncidences={selectedIncidences}
                    setSelectedIncidences={setSelectedIncidences}
                    redaccion={redaccion}
                    setRedaccion={setRedaccion}
                    isLoaded={isLoaded}
                  />
                </div>
              </Panel>
            </Collapse>
          </Row>
          <hr />
          <Row className="input-row">
            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
              <Panel
                header={<span className="generating">Observaciones:</span>}
                key="1"
                collapsible={isObservationsEnabled ? 'header' : 'disabled'}
              >
                <div style={{ marginTop: '16px' }}>
                  <ObservationsSection
                    observations={observations}
                    setObservations={setObservations}
                  />
                </div>
              </Panel>
            </Collapse>
          </Row>
          <hr />
          <Row className="input-row">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <span className="generating diagnostico">
                {isGenerating ? 'Generando diagnóstico' : isGenerated ? 'Diagnóstico generado:' : 'Diagnóstico'}
              </span>
              {!isGenerating && !alreadyGenerated && (
                <Button
                  className="generate-button vistaprevia-button"
                  onClick={regenerateDiagnosis}
                  disabled={!isReadyToGenerate}
                >
                  Generar
                </Button>
              )}
              {isGenerating && (
                <div className="dots" style={{ marginLeft: '10px' }}>
                  <span style={{ '--i': 1 }}>.</span>
                  <span style={{ '--i': 2 }}>.</span>
                  <span style={{ '--i': 3 }}>.</span>
                </div>
              )}
            </div>

            {(isGenerated || alreadyGenerated || isGenerating) && (
              <>
                {isGenerating ? (
                  <div className="skeleton-loader"></div>
                ) : (
                  <TextArea
                    rows={6}
                    value={vertexResponse.finalDiagnosis || ''}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setVertexResponse(prevState => ({
                            ...prevState,
                            finalDiagnosis: newValue,
                            modified: true
                        }));
                    }}
                    onBlur={(e) => {
                        const newValue = e.target.value;
                        const processedValue = processDiagnosisText(newValue);
                        setVertexResponse(prevState => ({
                            ...prevState,
                            finalDiagnosis: processedValue,
                            modified: true
                        }));
                    }}
                    placeholder="El diagnóstico aparecerá aquí"
                  />
                )}
              </>
            )}

            {(alreadyGenerated || isGenerated) && (
              <Button
                className="regenerate-button redesigned-secondary-button"
                onClick={regenerateDiagnosis}
                disabled={isGenerating || regenerateCount >= 3 || !isReadyToGenerate}
              >
                Regenerar Diagnóstico ({3 - regenerateCount} restantes)
              </Button>
            )}
          </Row>
          <Row className="center">
            <Button
              className="generate-button2 vistaprevia-button"
              onClick={toggleModal}
              disabled={!vertexResponse.finalDiagnosis || !isReadyToGenerate}
            >
              Vista previa de informe
            </Button>
          </Row>
          <Row className="center separate">
            <Button className="button4 redesigned-danger-button" onClick={() => navigate(-1)}>
              Volver
            </Button>
            <Popconfirm
              title="¿Estás seguro de que quieres iniciar un nuevo informe?"
              description="Perderás todos los datos cargados."
              okText="Sí, continuar"
              cancelText="Cancelar"
              onConfirm={nuevoInforme}
              okButtonProps={{ danger: true }}
            >
              <Button className="button5 redesigned-secondary-button">
                Nuevo informe
              </Button>
            </Popconfirm>
          </Row>
        </Col>
      </Row>
      <InformeModal
        isVisible={isModalVisible}
        onClose={() => {
          setIsModalVisibleInternal(false);
          setIsModalVisible(false);
        }}
        diagnostico={vertexResponse.finalDiagnosis || ''}
        solicitud={redaccion}
        observaciones={observations}
        images={[]}
        updateRightColumnState={updateStateFromModal}
      />
    </Col>
  );
};