import { useState, useEffect, useMemo, useCallback, useContext } from "react"
import { Space, Table, Tag, Input, Button, Col, Row, message, Dropdown, Menu, Modal } from "antd"
import { useNavigate } from "react-router-dom"
import {
  EditOutlined,
  CopyOutlined,
  DownloadOutlined,
  PlayCircleOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons"
import "../assets/styles/Dashboard.css"
import { useAuth } from "../contexts/authContext"
import { getReportsByVeterinary, deleteReport, duplicateReport, downloadPDF } from "../services/ReportService"
import ReportModal from "../components/ReportModal"
import { ImageContext } from "../contexts/imageContext/imageContext"

const { Column } = Table
const { Search } = Input

const Dashboard = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const { getToken } = useAuth()
  const [selectedReport, setSelectedReport] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [downloadingReportId, setDownloadingReportId] = useState(null)
  const { saveImagesToSessionStorage, clearImages } = useContext(ImageContext)

  const fetchData = useCallback(async () => {
    setLoading(true)
    const token = await getToken()
    if (!token) {
      throw new Error("No authentication token found.")
    }

    try {
      const fetchedReports = await getReportsByVeterinary(token)
      console.log("Fetched reports:", fetchedReports)
      setData(fetchedReports)
    } catch (error) {
      console.error("Error fetching reports:", error)
      setData([])
    } finally {
      setLoading(false)
    }
  }, [getToken])

  useEffect(() => {
    fetchData()
  }, [fetchData, getToken])

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase())
  }

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return Object.entries(item).some(([key, value]) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm)
        }
        if (typeof value === "object" && value !== null) {
          return JSON.stringify(value).toLowerCase().includes(searchTerm)
        }
        return false
      })
    })
  }, [data, searchTerm])

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (a.status.trim().toLowerCase() === "en progreso" && b.status.trim().toLowerCase() === "completado") {
        return -1
      }
      if (a.status.trim().toLowerCase() === "completado" && b.status.trim().toLowerCase() === "en progreso") {
        return 1
      }
      return new Date(b.reportDate) - new Date(a.reportDate)
    })
  }, [filteredData])

  const handleViewReport = (record) => {
    setSelectedReport(record)
    setIsModalOpen(true)
  }

  const handleCopyReport = async (record, copyType) => {
    const token = await getToken()
    if (!token) {
      throw new Error("No authentication token found.")
    }
    if (copyType === "follow-up") {
      const report = await duplicateReport(token, record.id)
      if (report) {
        message.success("Reporte copiado para seguimiento")
        fetchData()
      }
    } else {
      console.log("Copy type is not follow-up")
    }
  }

  const handleDownloadOrContinue = async (record) => {
    if (record.status.trim().toLowerCase() === "completado") {
      const token = await getToken()
      if (!token) {
        throw new Error("No authentication token found.")
      }
      setDownloadingReportId(record.id)
      try {
        await downloadPDF(token, record.id)
      } finally {
        setDownloadingReportId(null)
      }
    } else {
      console.log("Continuing report for:", record.patient.name)
      console.log("Report", record)

      // Access the ImageContext

      // Set the images in the context
      if (record.images && record.images.length > 0) {
        console.log("Setting images in context:", record.images)
        saveImagesToSessionStorage(record.images)
      } else {
        console.log("No images found, setting empty array in sessionStorage")
        console.log("Setting images in context:", record.images)
        sessionStorage.setItem("images", JSON.stringify(record.images))
      }

      // Prepare the formValues object
      const formValues = {
        tutor: record.patient?.guardian?.name || "",
        tutor_email: record.patient?.guardian?.email || "",
        profesional_derivante: record.requestingProfessional?.name || "",
        profesional_derivante_email: record.requestingProfessional?.email || "",
        nombreAnimal: record.patient?.name || "",
        edad: record.patient?.age?.value || "",
        edadUnidad: record.patient?.age?.unit || "",
        sexo: record.patient?.gender || "",
        especie: record.patient?.species || "",
        raza: record.patient?.breed || "",
        fecha: record.reportDate || new Date().toISOString(),
        tipo_estudio: record.type || "",
        tutor_email_disabled: false,
        profesional_email_disabled: false,
      }
      const report = {
        id: record.id,
      }

      // Set the formValues in sessionStorage
      sessionStorage.setItem("formValues", JSON.stringify(formValues))
      sessionStorage.setItem("report", JSON.stringify(report))
      // Navigate to /analisis
      navigate("/analisis")
    }
  }

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "¿Estás seguro de que quieres eliminar este reporte?",
      content: "Esta acción no se puede deshacer.",
      okText: "Sí, eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          const token = await getToken()
          if (!token) {
            throw new Error("No authentication token found.")
          }

          const success = await deleteReport(token, record.id)
          if (success) {
            message.success("Reporte eliminado exitosamente")
            fetchData() // Refresh the data after successful deletion
          }
        } catch (error) {
          console.error("Error deleting report:", error)
          message.error("Error al eliminar el reporte")
        }
      },
    })
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString("es-ES", { day: "2-digit", month: "2-digit", year: "numeric" })
  }

  const nuevoInforme = () => {
    sessionStorage.removeItem('formData2');
    sessionStorage.removeItem('formValues');
    sessionStorage.removeItem('vertexResponse');
    sessionStorage.removeItem('regenerateCount');
    sessionStorage.removeItem('diagnostico');
    sessionStorage.removeItem('observaciones');
    sessionStorage.removeItem('observationsForm');
    sessionStorage.removeItem('solicitud');
    sessionStorage.removeItem('report');
    clearImages();
    navigate('/analizar')
  }

  return (
    <Row justify="center" className="bodyy">
      <Col span={22}>
        <Row justify={"space-between"}>
          <Col span={10}>
            <Search
              placeholder="Buscar en todos los campos"
              allowClear
              enterButton="Buscar"
              size="large"
              onChange={(e) => handleSearch(e.target.value)}
              className="mb-4"
            />
          </Col>
          <Col span={6} className="text-rightt">
            <Button
              type="primary"
              size="large"
              className="mt-4 redesigned-secondary-button"
              onClick={() => {
                nuevoInforme()
              }}
            >
              CREAR NUEVO REPORTE
            </Button>
          </Col>
        </Row>
        <Row className="table-container">
          <Table
            dataSource={sortedData}
            className="dashboard-table"
            loading={loading}
            scroll={{ y: "calc(100vh - 350px)", x: false }}
            pagination={{ position: ["bottomCenter"], pageSize: 15 }}
          >
            {/* <ColumnGroup title="Paciente"> */}
            <Column title="Nombre" dataIndex={["patient", "name"]} key="patientName" width="10%" />
            {/* </ColumnGroup> */}
            {/* <ColumnGroup title="Propietario"> */}
            <Column title="Tutor" dataIndex={["patient", "guardian", "name"]} key="guardianName" width="10%" />
            {/* </ColumnGroup> */}
            <Column
              title="Fecha de reporte"
              dataIndex="reportDate"
              key="reportDate"
              render={(text) => formatDate(text)}
              width="10%"
            />
            <Column
              title="Derivante"
              dataIndex={["requestingProfessional", "name"]}
              key="requestingProfessional"
              width="10%"
            />
            <Column title="Tipo de estudio" dataIndex="type" key="studyType" width="8%" />
            <Column
              title="Estado"
              dataIndex="status"
              key="status"
              render={(status) => (
                <Tag color={status.trim().toLowerCase() === "en progreso" ? "volcano" : "green"}>
                  {status.toUpperCase()}
                </Tag>
              )}
              width="8%"
            />
            <Column
              title="Acción"
              key="action"
              render={(_, record) => (
                <Space size="small" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className="action-buttons-wrapper">
                    <button
                      className={`action-button ${
                        record.status.trim().toLowerCase() === "completado"
                          ? "download-button vistaprevia-button"
                          : "continue-button"
                      }`}
                      onClick={() => handleDownloadOrContinue(record)}
                      disabled={downloadingReportId === record.id}
                    >
                      {record.status.trim().toLowerCase() === "completado" ? (
                        <>
                          {downloadingReportId === record.id ? (
                            <span>Descargando...</span>
                          ) : (
                            <>
                              <DownloadOutlined className="separar" /> Descargar
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <PlayCircleOutlined className="separar" /> Continuar
                        </>
                      )}
                    </button>
                    {record.status.trim().toLowerCase() === "completado" ? (
                      <button
                        className="action-button redesigned-secondary-button"
                        onClick={() => handleViewReport(record)}
                      >
                        <EditOutlined />
                      </button>
                    ) : (
                      <div className="action-button-placeholder"></div>
                    )}
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1" onClick={() => handleCopyReport(record, "complete")}>
                            Copiar completo
                          </Menu.Item>
                          <Menu.Item key="2" onClick={() => handleCopyReport(record, "follow-up")}>
                            Copia para seguimiento
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <button className="action-button copy-button">
                        <CopyOutlined />
                      </button>
                    </Dropdown>
                    <button className="action-button redesigned-danger-button" onClick={() => handleDelete(record)}>
                      <DeleteOutlined />
                    </button>
                  </div>
                  <div className="action-dropdown">
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="1"
                            onClick={() => handleDownloadOrContinue(record)}
                            disabled={downloadingReportId === record.id}
                          >
                            {record.status.trim().toLowerCase() === "completado"
                              ? downloadingReportId === record.id
                                ? "Descargando..."
                                : "Descargar"
                              : "Continuar"}
                          </Menu.Item>
                          {record.status.trim().toLowerCase() === "completado" && (
                            <Menu.Item key="2" onClick={() => handleViewReport(record)}>
                              Ver reporte
                            </Menu.Item>
                          )}
                          <Menu.Item key="3" onClick={() => handleCopyReport(record, "complete")}>
                            Copiar completo
                          </Menu.Item>
                          <Menu.Item key="4" onClick={() => handleCopyReport(record, "follow-up")}>
                            Copia para seguimiento
                          </Menu.Item>
                          <Menu.Item key="5" onClick={() => handleDelete(record)}>
                            Eliminar
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <button className="action-button">
                        <MoreOutlined />
                      </button>
                    </Dropdown>
                  </div>
                </Space>
              )}
              width="15%"
            />
          </Table>
        </Row>
      </Col>
      <ReportModal report={selectedReport} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Row>
  )
}

export default Dashboard

